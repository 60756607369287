import React from 'react'
import classNames from 'classnames'


const cx = classNames.bind(require('./styles.scss'))

const DirectionInfo = () => {

    return (
        <main className={cx('direction-info', 'container')}>
            <h1 className={cx('direction-info__title')}>Сведения о направлениях деятельности</h1>
            <p className={cx('direction-info__text')}>«В соответствии с Постановлением Правительства РФ от 30.09.2022 N 1729 "Об утверждении Положения о
                государственной аккредитации российских организаций, осуществляющих деятельность в области
                информационных технологий" <strong>доводим сведения о направлениях нашей деятельности</strong>,
                осуществляемой в области
                информационных технологий, в части оказываемых услуг и разрабатываемых продуктов:

                <ol>
                    <li>научные исследования и разработки в области стратегических компьютерных технологий и
                        программного обеспечения;
                    </li>
                    <li>разработка программного обеспечения;</li>
                    <li>создание новых технологий, программных и иных продуктов в области стратегических компьютерных
                        технологий и программного обеспечения;
                    </li>
                    <li>деятельность в области компьютерных и информационных технологий;</li>
                    <li>деятельность по созданию, обработке и использованию баз данных и информационных ресурсов.»</li>
                </ol>
            </p>
        </main>
    )
}

export default DirectionInfo
