import React, {useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'

import { Loader } from '../common/Loader/Loader'
import ScrollToTopButton from '../common/ScrollToTopButton/ScrollToTopButton'
import DirectionInfo from '../components/DirectionInfo/DirectionInfo'

const CompanyDirections = () => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false)
        }, 2000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <>
            {isLoading
                ? <Loader/>
                : <>
                    <DirectionInfo />
                    <ScrollToTopButton />
                </>
            }
        </>
    )
}

export default CompanyDirections
