import classNames from 'classnames'
import { SvgIcon } from '../../common/SvgIcon/SvgIcon'
import { Link } from 'react-router-dom'


const cx = classNames.bind(require('./styles.scss'))

const Footer = () => {

    return (
        <footer className={cx('footer')}>
            <nav className={cx('footer__wrapper', 'container')}>
                <div className={cx('footer__wrapper-col')}>
                    <h5 className={cx('footer__title')}>Адрес</h5>
                    <p className={cx('footer__text')}>г. Томск, ул. Б. Хмельницкого, д. 41</p>
                </div>
                <div className={cx('footer__wrapper-col')}>
                    <h5 className={cx('footer__title')}>Контакты</h5>
                    <p className={cx('footer__text')}>Телефон: <a className={cx('footer__link')}
                                                                  href='tel:+79917770591'>+7 (991) 777-05-91</a>
                    </p>
                    <p className={cx('footer__text')}>Email: <a className={cx('footer__link')}
                                                                href='mailto:info@qeep.pro'>info@qeep.pro</a>
                    </p>
                    <ul className={cx('footer__text', 'footer__list')}>
                        <li>ООО "Онлайн генератор приложений"</li>
                        <li>ОГРН 1227000006837</li>
                        <li>ИНН 7017494897</li>
                        <li>КПП 701701001</li>
                        <li>ОКПО 81152080</li>
                    </ul>
                </div>
                <div className={cx('footer__wrapper-col')}>
                    <h5 className={cx('footer__title')}>Информация о партнерах</h5>
                    <a className={cx('footer__link')} href='/doc/privacy-policy.pdf'>
                        Политика конфиденциальности
                    </a>
                    <Link className={cx('footer__link')} to='/direction-info'>
                        Сведения о направлениях деятельности
                    </Link>
                </div>
                <div className={cx('footer__wrapper-col')}>
                    <Link to='/' className={cx('footer__link-logo')}>
                        <SvgIcon src='/logo/logo-white.svg' width='70'/>
                    </Link>
                    <p className={cx('footer__text')} >Аккредитованная IT-компания</p>
                </div>
            </nav>
        </footer>
    )
}

export default Footer
