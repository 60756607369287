import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import NotFound from './components/NotFound/NotFound'
import CompanyDirections from './pages/CompanyDirections'
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
    return (
       <>
           <Header/>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/direction-info' element={<CompanyDirections />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
           <Footer/>
       </>
)
}

export default App
